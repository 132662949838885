import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import Hero from 'e84-website-styles/src/components/Hero';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faCaretDown';

const CubesatPreview = () => {

  const helmet_settings = {
    title: 'Find Out When All Systems Are Go - CubeSat Data',
    bodyAttributes: {
      class: `page-cubesat page-cubesat-preview`,
    },
  };

  return (
    <Layout>
      <Helmet { ...helmet_settings } />

      <div className="CubesatPreview-landing">
        <Hero modifierClass="homepage-hero">
          <h1 className="animate-1 marg-bot-1">
            Cubesat <small>Preview</small>
          </h1>
          <p className="animate-2">
            Tell us a bit about your program and we’ll let you know when all
            systems are go.
          </p>
        </Hero>

        <section className="site-section cubesat-preview-form animate-3">
          <div className="container">
            <div className="row">
              <div className="eight columns offset-by-two">
                <form className="form"
                  action="https://element84.us13.list-manage.com/subscribe/post?u=a7a7fcb1ce46c4d001fc76289&amp;id=cad6349a3b"
                  method="post"
                  name="mc-embedded-subscribe-form"
                >
                  <div className="form-item" data-is-required="true">
                    <label htmlFor="mce-NAME">
                      Name <span className="form-item-asterisk">*</span>
                    </label>

                    <input type="text"
                      name="NAME"
                      id="mce-NAME"
                      required="required"
                    />

                    <p className="form-item-helper">
                      Company name or individual&apos;s name
                    </p>
                  </div>

                  <div className="form-item" data-is-required="true">
                    <label htmlFor="mce-EMAIL">
                      Email Address{ ' ' }
                      <span className="form-item-asterisk">*</span>
                    </label>

                    <input type="email"
                      name="EMAIL"
                      id="mce-EMAIL"
                      required="required"
                    />
                  </div>

                  <div className="form-item" data-is-required="true">
                    <label htmlFor="mce-INTEREST">
                      I&apos;m Interested In{ ' ' }
                      <span className="form-item-asterisk">*</span>
                    </label>

                    <span className="form-select">
                      <select name="INTEREST"
                        id="mce-INTEREST"
                        required="required"
                      >
                        <option>-- Select --</option>
                        <option value="Managing data for an existing remote sensing project">
                          Managing data for an existing remote sensing project
                        </option>
                        <option value="Building a data service for my remote sensing project">
                          Building a data service for my remote sensing project
                        </option>
                        <option value="Data management for an upcoming or future mission">
                          Data management for an upcoming or future mission
                        </option>
                      </select>
                      <FontAwesomeIcon icon={ faTimes } />
                    </span>
                  </div>

                  <div className="form-item" data-is-required="true">
                    <label htmlFor="mce-NEED">
                      I’m most in need of{ ' ' }
                      <span className="form-item-asterisk">*</span>
                    </label>

                    <span className="form-select">
                      <select name="NEED" id="mce-NEED" required="required">
                        <option>-- Select --</option>
                        <option value="Ingest and archive of data">
                          Ingest and archive of data
                        </option>
                        <option value="Data customization and archives of convenience">
                          Data customization and archives of convenience
                        </option>
                        <option value="Data distribution (API or service)">
                          Data distribution (API or service)
                        </option>
                        <option value="Data visualization and dashboards">
                          Data visualization and dashboards
                        </option>
                      </select>
                      <FontAwesomeIcon icon={ faTimes } />
                    </span>
                  </div>

                  <div className="form-item">
                    <label htmlFor="mce-LAUNCH_DT">Launch Date</label>

                    <input type="text" name="LAUNCH_DT" id="mce-LAUNCH_DT" />

                    <p className="form-item-helper">
                      Planned or estimated date of launch
                    </p>
                  </div>

                  <div className="form-item">
                    <label htmlFor="mce-COMMENT">
                      Anything else we should know?
                    </label>

                    <textarea id="mce-COMMENT" name="COMMENT" />
                  </div>

                  <div className="form-hidden" aria-hidden="true">
                    <input type="text"
                      name="b_a7a7fcb1ce46c4d001fc76289_cad6349a3b"
                      tabIndex="-1"
                    />
                  </div>

                  <div className="form-item">
                    <input type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button button-lg button-prim_gradient button-shadow"
                    />
                  </div>

                  <div className="form-caption">
                    <p className="italic">
                      <span className="form-item-asterisk">*</span> indicates
                      required
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );

};

export default CubesatPreview;
